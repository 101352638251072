<template>
  <form-container
    :title="title"
    buttonText="Guardar"
    :useLoading="!themeDetail"
    @submit="save">
    <template v-slot:content>
      <div v-if="themeDetail">
        <v-row no-gutters class="logo-container">
          <v-col cols="12">
            <div class="section-title">Logo</div>
            <div class="section-description">Añada el logotipo de la aplicación, se recomienda una imagen con unas dimensiones de <b>360</b> píxeles de ancho y <b>86</b> píxeles de alto.</div>
            <image-upload :image_url="themeDetail.logo" @change="logoChange" />
          </v-col>
        </v-row>
        <v-row no-gutters class="banner-images-container">
          <v-col cols="12">
            <div class="section-title">Imágenes del banner</div>
            <div class="section-description">Añada las imágenes que desea mostrar en el banner de la aplicación, se recomienda una imagen con unas dimensiones de <b>2494</b> píxeles de ancho y <b>896</b> píxeles de alto.</div>
            <multiple-image-upload :image_urls="themeDetail.banner_images" />
          </v-col>
        </v-row>
        <v-row no-gutters class="banner-images-container">
          <v-col cols="12">
            <div class="section-title">Imágenes del banner para teléfonos móviles</div>
            <div class="section-description">Añada las imágenes que desea mostrar en el banner de la aplicación para teléfonos móviles, se recomienda una imagen con unas dimensiones de <b>2719</b> píxeles de ancho y <b>580</b> píxeles de alto.</div>
            <multiple-image-upload :required="false" :image_urls="themeDetail.mobile_banner_images" />
          </v-col>
        </v-row>
      </div>
    </template>
  </form-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import ImageUpload from '@/components/Upload/ImageUpload'
import MultipleImageUpload from '@/components/Upload/MultipleImageUpload'
import { isRequired, isNumeric, isEmail } from '@/shared/validations'
import { buildThemeUploadUrls } from '@/shared/file'

export default {
  components: {
    FormContainer,
    ImageUpload,
    MultipleImageUpload
  },
  data () {
    return {
      title: 'Editar tema de la aplicación',
      logo: null
    }
  },
  computed: {
    ...mapState('theme', ['themeDetail']),
    ...mapState('shared', ['error']),
    ...mapState('file', ['files'])
  },
  methods: {
    isRequired,
    isNumeric,
    isEmail,
    ...mapActions('theme', ['getTheme', 'saveTheme']),
    ...mapActions('notification', ['showNotification']),
    ...mapActions('file', ['saveFiles']),
    logoChange (name) {
      this.logo = name
    },
    async save () {
      if (this.logo) {
        this.themeDetail.logo = this.logo
      }

      await this.saveTheme(this.themeDetail)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      if (this.files.length > 0) {
        const uploadUrlInput = buildThemeUploadUrls(this.files)
        await this.saveFiles(uploadUrlInput)

        if (this.error) {
          this.showNotification({ message: 'Ocurrió un error al subir las imágenes, por favor inténtelo nuevamente', color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
          return
        }
      }

      this.showNotification({ message: 'Tema actualizado exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
    }
  },
  mounted () {
    this.getTheme()
  }
}
</script>
<style scoped>
.section-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}
.section-description {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}
.banner-images-container {
  margin-top: 0.75rem;
}
</style>
