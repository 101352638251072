export const UPLOAD_URL_TYPE_PRODUCT_IMAGE = 'product-image'
export const UPLOAD_URL_TYPE_THEME_IMAGE = 'theme-image'

export function buildProductUploadUrls (productID, files) {
  const uploadUrlInput = {
    upload_url_type: UPLOAD_URL_TYPE_PRODUCT_IMAGE,
    urls: []
  }

  for (const file of files) {
    const url = {
      product_id: productID,
      name: file.name
    }

    uploadUrlInput.urls.push(url)
  }

  return uploadUrlInput
}

export function buildThemeUploadUrls (files) {
  const uploadUrlInput = {
    upload_url_type: UPLOAD_URL_TYPE_THEME_IMAGE,
    urls: []
  }

  for (const file of files) {
    const url = { name: file.name }

    uploadUrlInput.urls.push(url)
  }

  return uploadUrlInput
}
