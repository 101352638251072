<template>
  <div>
    <div class="images-container">
      <div class="single-image-container" v-if="hasImage"
        @drop.prevent="dropImage"
        @dragenter.prevent="setDropActive"
        @dragover.prevent="setDropActive"
        @dragleave.prevent="setDropInactive">
        <div class="single-image-content">
          <v-img class="single-image" :src="image" contain></v-img>
        </div>
        <div class="change-image-container">
          <v-btn class="btn-icon-default" fab small raised>
            <v-icon class="camera-icon">mdi-camera-enhance</v-icon>
          </v-btn>
        </div>
        <div class="drop-image-container" v-if="dropActive" />
        <v-file-input class="input-file" :rules="[ isRequired ]" @change="addImage($event)" hide-details></v-file-input>
      </div>
      <div :class="[{ 'upload-container': !showErrorMessage, 'upload-container-error': showErrorMessage }]" v-else
        @drop.prevent="dropImage"
        @dragenter.prevent="setDropActive"
        @dragover.prevent="setDropActive"
        @dragleave.prevent="setDropInactive">
        <div class="text-container text-container-with-images">
          <v-row no-gutters class="text-center">
            <v-col cols="12">
              <v-icon class="camera-icon">mdi-camera-enhance</v-icon>
            </v-col>
            <v-col cols="12" class="text-content">
              <div class="upload-text">Agrega o arrastra tu imagen aquí</div>
            </v-col>
          </v-row>
          <div class="drop-image-container" v-if="dropActive" />
        </div>
        <v-file-input class="input-file" :rules="[ isRequired ]" @change="addImage($event)" hide-details></v-file-input>
      </div>
    </div>
    <v-scroll-y-transition>
      <div class="v-text-field__details error-message-container" v-if="showErrorMessage">
        <div class="v-messages theme--light error--text">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">Requerido.</div>
          </div>
        </div>
      </div>
    </v-scroll-y-transition>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { cloneObject } from '@/shared/clone'
import { fileToBase64 } from '@/shared/convert'
import { newSha256 } from '@/shared/format'

export default {
  props: {
    image_url: {
      default: null
    }
  },
  computed: {
    hasImage () {
      return !!this.image
    },
    isRequired () {
      return () => {
        const hasImage = this.hasImage
        if (!hasImage && !this.firstExecution) {
          this.showErrorMessage = true
        }

        this.firstExecution = false

        return hasImage
      }
    }
  },
  data () {
    return {
      refresh: false,
      showErrorMessage: false,
      firstExecution: true,
      image: null,
      dropEvents: ['dragenter', 'dragover', 'dragleave', 'drop'],
      dropActive: false
    }
  },
  methods: {
    ...mapActions('file', ['addUploadFile', 'deleteUploadFile']),
    preventDefaults (e) {
      e.preventDefault()
    },
    addEventsListener () {
      this.dropEvents.forEach((name) => {
        document.addEventListener(name, this.preventDefaults)
      })
    },
    removeEventsListener () {
      this.dropEvents.forEach((name) => {
        document.removeEventListener(name, this.preventDefaults)
      })
    },
    setDropActive () {
      this.dropActive = true
    },
    setDropInactive () {
      this.dropActive = false
    },
    async getFileName (file) {
      const result = await fileToBase64(file).catch(e => Error(e))
      if (result instanceof Error) {
        console.error('Error: ', result.message)
        return
      }

      const base64Parts = result.split(',')
      if (base64Parts.length !== 2) {
        console.error('Error: invalid base64 format')
        return
      }

      const name = await newSha256(base64Parts[1])

      return name
    },
    async addImage (file) {
      if (!file) {
        return
      }

      const name = await this.getFileName(file)
      if (!name) {
        return
      }

      if (this.image) {
        this.deleteUploadFile(this.image)
      }

      this.addUploadFile({ name, file })

      this.image = URL.createObjectURL(file)
      this.$emit('change', name)
      this.showErrorMessage = false
    },
    dropImage (e) {
      this.dropActive = false

      const dataTransfer = e.dataTransfer
      if (!dataTransfer || !dataTransfer.files || dataTransfer.files.length === 0) {
        return
      }

      this.addImage(dataTransfer.files[0])
    }
  },
  mounted () {
    this.image = cloneObject(this.image_url)
    this.addEventsListener()
  },
  beforeDestroy () {
    this.removeEventsListener()
  }
}
</script>
<style scoped>
.images-container {
  display: -webkit-box;
  max-width: 100%;
  overflow-x: scroll;
}
.upload-container {
  position: relative;
  border: 0.063rem dashed var(--app-button-bg-color);
  height: 7.5rem;
  border-radius: 0.188rem;
  font-size: 0.75rem;
  margin: 0.5rem 0 0.438rem 0;
}
.upload-container:hover {
  border: 0.125rem solid var(--app-button-bg-color);
}
.upload-container-error {
  position: relative;
  border: 0.063rem dashed #FF5252;
  height: 7.5rem;
  border-radius: 0.188rem;
  font-size: 0.75rem;
  margin: 0.5rem 0 0.438rem 0;
}
.upload-container-error:hover {
  border: 0.125rem solid #FF5252;
}
.text-container {
  display: flex;
  height: 100%;
  align-items: center;
}
.text-container-with-images {
  height: 7.5rem;
  width: 7.5rem;
}
.camera-icon {
  color: var(--app-button-bg-color);
}
.text-content {
  display: flex;
  justify-content: center;
}
.drop-image-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background: white;
}
.input-file{
  opacity: 0.0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.input-file >>> .v-input__slot {
  opacity: 0.0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.input-file >>> .v-text-field__slot {
  width: 100%;
  height: 100%;
}
.input-file >>> .v-text-field__slot {
  position: absolute;
}
.input-file >>> .v-input__append-inner {
  width: 0;
  padding: 0;
  margin: 0;
}
.upload-text {
  max-width: 8.125rem;
  margin-top: 0.313rem;
}
.single-image-container {
  position: relative;
  box-shadow: 0 0.063rem 0.125rem 0 rgba(0,0,0,.15),0 0 0.063rem 0.063rem rgba(0,0,0,.05);
  width: 7.5rem;
  height: 7.5rem;
  margin: 0.5rem 0 0.438rem 0.1rem
}
.single-image-content {
  position: absolute;
  height: 100%;
  width: 100%;
}
.single-image {
  width: 7.5rem;
  height: 7.5rem;
}
.change-image-container {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: white;
}
.single-image-container:hover .change-image-container {
  display: flex;
}
.error-message-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
</style>
