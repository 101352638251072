<template>
  <form-loader v-if="loading" />
  <v-row class="form-row-container" no-gutters v-else-if="response">
    <v-col xl="8" lg="9" md="11" sm="11" cols="12">
      <v-card :class="{ 'card-wrapper mx-auto': $vuetify.breakpoint.smAndUp }" elevation="2" tile>
        <flex-view-layout class="flex-layout-container">
          <template v-slot:content>
            <div class="card-container">
              <div class="card-content">
                <div class="icon-container">
                  <v-icon :class="['result-icon', { 'success-icon': isSuccessful, 'failure-icon': !isSuccessful }]">{{response.icon}}</v-icon>
                </div>
                <div class="card-description">
                  {{response.icon_text}}
                </div>
                <div class="card-title">
                  {{response.title}}
                </div>
                <div class="card-description" v-for="(description, index) in response.descriptions" :key="'link_account_card_description_'+index">
                  {{index+1}}. {{description}}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <slot name="footer">
              <v-btn class="text-none btn-default" depressed large :loading="loading" @click="goToRouteByName(response.button_route)">
                {{response.button_text}}
              </v-btn>
            </slot>
          </template>
        </flex-view-layout>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import FormLoader from '@/components/Loader/FormLoader'
import FlexViewLayout from '@/layouts/FlexViewLayout'
import { LINK_ACCOUNT_RESPONSE } from '@/shared/linkAccountResponse'

export default {
  mixins: [navigationMixin],
  components: {
    FormLoader,
    FlexViewLayout
  },
  computed: {
    ...mapState('shared', ['error']),
    ...mapState('shared', ['loading']),
    isSuccessful () {
      return this.status === 'success'
    }
  },
  data () {
    return {
      response: null,
      status: 'pending'
    }
  },
  methods: {
    ...mapActions('paymentgateway', ['confirmAuthorization']),
    ...mapActions('notification', ['showNotification']),
    home () {
      this.$router.push('/')
    },
    async linkAccount (id, code) {
      const linkApplication = {
        id,
        code
      }

      await this.confirmAuthorization(linkApplication)
      if (this.error) {
        this.setStatus('failure')
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.setStatus('success')
    },
    setStatus (status) {
      this.status = status
      this.response = LINK_ACCOUNT_RESPONSE[this.status]
    }
  },
  async mounted () {
    const id = this.$route.query.state
    const code = this.$route.query.code
    if (!id || !code) {
      this.home()
      return
    }

    this.linkAccount(id, code)
  }
}
</script>
<style scoped>
.form-row-container {
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-wrapper {
  min-height: 38.75rem;
}
.flex-layout-container {
  min-height: inherit;
  padding: 2.125rem 2.75rem;
}
.card-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 2rem;
  color: var(--app-text-color);
}
.card-content {
  max-width: 36.25rem;
}
.icon-container{
  margin-bottom: 0.75rem;
}
.result-icon {
  font-size: 3.25rem;
  width: 3.25rem;
  height: 3.25rem;
}
.success-icon {
  color: var(--app-button-bg-color-success);
}
.failure-icon {
  color: var(--app-button-bg-color-failure);
}
.card-title {
  font-size: 1rem;
  font-weight: 600;

  margin-top: 1rem;
  margin-bottom: 1.75rem;
}
.card-description {
  margin-top: 0.75rem;
  font-size: 0.875rem;
}

@media only screen and (max-width: 37.5rem) {
  .form-row-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .card-wrapper {
    min-height: inherit;
  }
  .flex-layout-container {
    padding: 1.5rem 1.25rem 0.25rem 1.25rem;
  }
}
</style>
