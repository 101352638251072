export const LINK_ACCOUNT_RESPONSE = {
  success: {
    icon: 'mdi-check-all',
    icon_text: 'Vinculación exitosa',
    title: 'Tu cuenta de mercado pago quedó vinculada exitosamente, ahora podrás:',
    descriptions: [
      'Realizar ventas de forma online de manera segura y rápida.',
      'Ofrecer a tus clientes una experiencia de compra adaptada y accesible desde cualquier dispositivo.',
      'Ofrecer a tus clientes pago en efectivo o pago con dos tarjetas si la suma a pagar es alta.'
    ],
    button_text: 'Ir al inicio',
    button_route: 'home'
  },
  failure: {
    icon: 'mdi-close-box-multiple',
    icon_text: 'Vinculación fallida',
    title: 'Ocurrió un error al intentar vincular tu cuenta de mercado pago, intentalo nueva mente para que puedas:',
    descriptions: [
      'Realizar ventas de forma online de manera segura y rápida.',
      'Ofrecer a tus clientes una experiencia de compra adaptada y accesible desde cualquier dispositivo.',
      'Ofrecer a tus clientes pago en efectivo o pago con dos tarjetas si la suma a pagar es alta.'
    ],
    button_text: 'Intentar de nuevo',
    button_route: 'payment-gateway-auth'
  }
}
